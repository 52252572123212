import React, {useState} from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

function Section() {
  const history = useHistory();
  const handleSimpleRedirect = (value) => history.push("/results/"+value);
  const [stateSearch,setStateSearch] = useState('');
  const handleRedirect = () => history.push("/results/inspireme");
  
  
  return (
    <Wrap>
      <ItemText>
        <H1Styled>
          <SpanColorOrange>ARE</SpanColorOrange>{" "}
          <SpanColorYellow>YOU</SpanColorYellow>{" "}
          <SpanColorBrow>STRUGGULING</SpanColorBrow>{" "}
          <SpanColorPink>TO MAKE</SpanColorPink>{" "}
          <SpanColorBlue>A CHOICE ?</SpanColorBlue>
        </H1Styled>{" "}
        <H2Styled>
          <SpanColorBlue>Donation</SpanColorBlue>{" "}
          <SpanColorPink>Keep</SpanColorPink>{" "}
          <SpanColorYellow>Simple.</SpanColorYellow>{" "}
          <SpanColorGreen>Start</SpanColorGreen>
          <SpanColorBrow> looking</SpanColorBrow> for{" "}
          <SpanColorPinkUnderline>
            your next impactful donation.
          </SpanColorPinkUnderline>
        </H2Styled>
      </ItemText>
      <StyledFormDiv>
        <SearchBar>
          <div class="search__container">
            <form >
            <input
              className="search__input"
              type="text"
              placeholder="Search information about non-profit organisation"
              onChange={event => {setStateSearch(event.target.value.replace(/[^a-zA-Z ]/g, ""))}}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleSimpleRedirect(stateSearch);
                }
              }}
              />
            </form>
          </div>
        </SearchBar>
        <DontKnowSection>
          <Paragraph>
            <p>Still don't know ?</p>
          </Paragraph>
          <Button onClick={handleRedirect}>INSPIRE ME</Button>
        </DontKnowSection>
      </StyledFormDiv>
    </Wrap>
  );
}

export default Section;

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; //vertical alignment
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

//First Title H1
const ItemText = styled.div`
  display: inline;
`;

const H1Styled = styled.h1`
  width: 600px;
  font-size: 64px;
  line-height: 1;
  padding-left: 3rem;
  padding-top: 1rem;
  @media (max-width: 768px) {
    font-size: 44px;
    width: 100%;
    padding: 2rem;
    line-height: 1;
  }
  @media (max-width: 412px) {
    font-size: 40px;
    width: 80%;
    padding: 2rem 7rem 2rem 2rem;
    line-height: 1;
  }
  @media (max-width: 375px) {
    font-size: 38px;
    width: 80%;
    padding: 2rem 4rem 0 2rem;
    line-height: 1;
  }
`;

//Second Title H2 Below H1

const H2Styled = styled.h2`
  font-size: 44px;
  padding-left: 10rem;
  padding-right: 2rem;
  line-height: 1.2;
  @media (max-width: 768px) {
    font-size: 30px;
    width: 100%;
    padding: 1rem;
  }
  @media (max-width: 540px) {
    font-size: 28px;
    width: 95%;
    padding: 1rem;
  }
  @media (max-width: 375px) {
    font-size: 23px;
    width: 80%;
    padding: 2rem 2rem 2rem 2.5rem;
  }
`;

//Styled Form

const StyledFormDiv = styled.div`
  width: 100vw;
  height: 50vh;
  margin: 0 auto;
  text-align: center;
  width: 100%;
`;

// Main Search Bar
const SearchBar = styled.div`
  text-align: center;
  .search {
    &__title {
      font-size: 22px;
      font-weight: 900;
      text-align: center;
      color: #ff8b88;
    }

    &__input {
      width: 50%;
      padding: 12px 24px;
      background-color: white;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 40px;

      color: #575756;

      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: 95% center;
      border-radius: 50px;
      border: 1px solid white;
      transition: all 250ms ease-in-out;
      backface-visibility: hidden;
      transform-style: preserve-3d;

      &::placeholder {
        color: Black;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }

      &:hover,
      &:focus {
        padding: 12px 0;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 2px solid #f20c36;
        border-radius: 2px;
        background-position: 100% center;
      }
      @media (max-width: 768px) {
        width: 70%;
        line-height: 30px;
        &::placeholder {
          letter-spacing: 1px;
        }
      }
    }
  }
`;

//Don't Know section
const DontKnowSection = styled.div`
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; //vertical alignment
  align-items: center;
  line-height: 0;
`;
const Paragraph = styled.div`
  width: 50%;
  p {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 23px;
    width: 100%;
    padding: 1rem;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 156px;
  background: linear-gradient(
    -50deg,
    #f24405 20%,
    #f20c36 20%,
    #049dd9 30%,
    #f29f05 20%
  );
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0 0 0 / 30%);
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(-60deg, #f20c36 20%, #f24405 20%);
    border-radius: 0px;
  }
`;

//Color Sytled
const SpanColorOrange = styled.span`
  color: #f24405;
`;

const SpanColorPink = styled.span`
  color: #f20c36;
`;

const SpanColorBrow = styled.span`
  color: #bf2a18;
`;

const SpanColorYellow = styled.span`
  color: #f29f05;
`;

const SpanColorBlue = styled.span`
  color: #049dd9;
`;

const SpanColorGreen = styled.span`
  color: #81e51b;
`;

const SpanColorPinkUnderline = styled(SpanColorPink)`
  text-decoration: underline #f20c36;
`;
