import "./index.css";

import Home from "./components/Home";
import SearchResult from "./components/SearchResult"
import { BrowserRouter as Router, Routes, Route, Link, Switch } from 'react-router-dom';


export default function App() {
  return (
    <Router>
      <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/results">
            <SearchResult />
          </Route>
      </Switch>
    </Router>
  );
}
