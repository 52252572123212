import React from "react";
import styled from "styled-components";



function Header() {
  return (
    <Container>
      <Logo>
        <img
          alt="logo"
          src="https://siasky.net/CABPi1tD4fzmUoiGpDPcmJP7YmKk7chlpRrwwYCk_oF5ww"
        />
      </Logo>
    </Container>
  );
}

export default Header;

const Container = styled.div`
`;

const Logo = styled.div `
  position: absolute; 
  margin:10px; 
  width: 100%;
  text-align:center;
  img {
    width: 300px;
    @media (max-width: 768px) {
      width: 240px;
    }
  }
`;
