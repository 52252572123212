import React from "react";
import styled from "styled-components";
import Section from "./Section";
import Header from "./Header";

function Home() {
  return (
    <Container>
      <Header />
      <Section />
    </Container>
  );
}

export default Home;

const Container = styled.div`
  height: 110vh;
`;
