import React, { useState } from "react";

import styled from "styled-components";
import Header from "./Header";

import { BiInfinite } from "react-icons/bi";
import { GiHealthNormal } from "react-icons/gi";
import { FaPrayingHands } from "react-icons/fa";
import { BiPaint } from "react-icons/bi";
import { GiMeepleArmy } from "react-icons/gi";
import { GiCastle } from "react-icons/gi";
import { MdEmojiNature } from "react-icons/md";
import { CgShapeSquare } from "react-icons/cg";
import { AiFillStar } from "react-icons/ai";
import { Charities } from "./Charities";

function SearchResult() {
  const [searchResults, setSearchResults] = useState('');


  const unique = [...new Set(Charities.map((item) => item.CharityCategory))];
  unique.unshift("All");
  return (
    <Container>
      <Header />
      <SearchBar>
          <div class="search__container">
            <input
              className="search__input"
              type="text"
              placeholder="Search information about non-profit organisation"
              onChange={(event) => setSearchResults(event.target.value)}
            />
          </div>
        </SearchBar>
      <Centered>
        <Nav>
          <NavMenu>
            {unique.map((item, index) => {
              return (
                <a>
                  <span className=".spanlator">
                    {item}
                  </span>
                </a>
              );
            })}
          </NavMenu>
        </Nav>
        <Content>
          {Charities.filter((item) => {
              // if no terms specify return everything
             if (searchResults == "") {
              return item;
            } else if (item.CharityName.toLowerCase().includes(searchResults.toLowerCase())){
              return item;
            } else if (item.CharityCountry.toLowerCase().includes(searchResults.toLowerCase())) {
              return item;
            }
            else if (item.CharityCategory.toLowerCase().includes(searchResults.toLowerCase())) {
              return item;
            }
            else if (item.CharityCity.toLowerCase().includes(searchResults.toLowerCase())) {
              return item;
            }
          }).map((item, index) => {
            return (
              <Wrap key={index}>
                <ImgText>
                  <Box>
                    <LogoCharity src={item.CharityLogo} />
                  </Box>
                  <Box>
                    <CharityLocation>
                      {item.CharityCity},{item.CharityCountry}
                    </CharityLocation>
                  </Box>
                  <Box>
                    <CharityTitle>{item.CharityName}</CharityTitle>
                  </Box>
                  <Box>
                    <ButtonGive>GIVE FEEDBACK</ButtonGive>
                  </Box>
                </ImgText>
              </Wrap>
            );
          })}
        </Content>
      </Centered>
    </Container>
  );
}

export default SearchResult;

const styleIcon = { color: "white", height: "20" };
const styleIconBlue = { color: "blue", height: "20" };

const Container = styled.div`
  height: 110vh;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90%;
`;

const Nav = styled.nav`
  height: 100px;
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
  overflow-x: hidden;
`;

const NavMenu = styled.div`
  display: flex;
  margin-left: 20px;
  align-items:left;
  a {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    }
  }
  .spanoff {
    font-size: 14px;
    letter-spacing: 1.42px;
    color: white;
    padding: 0 3px;
    position: relative;
  }
  a:hover{
    background-color:#707070;
    transform: all ease-out 250ms;
    opacity:0.8;
    border-radius:4px;
    line-height:1.8;
  }
  .spanlator {
    font-size: 14px;
    letter-spacing: 1.42px;
    color: white;
    padding: 0 3px;
    position: relative;
    border-bottom: solid 2px white;
  }
`;

// Display result in grid form
const Content = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 0 0 0 0;
  height: 75%;
  width: 90%;
`;

const Wrap = styled.div`
  max-height:200px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  border: 3px solid rgba(249, 249, 249, 0.1);
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  transition: all 250ms cubic-bezier(0.24, 0.46, 0.45, 0.94) 0s;
  &:hover {
    transform: scale(1.05);
    border: 4px solid rgba(249, 249, 249, 0.8);
    transition: all 250ms;
  }
`;

const ImgText = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 5px;
  padding: 0 10px 10px 10px;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
`;

const Box = styled.div`
  color: #707070;
  &:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1/1;
    grid-row: 1/3;
  }
  &:nth-child(2) {
    grid-column: 2/3;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &:nth-child(3) {
    grid-row: 2/1;
    grid-column: 2/4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: left;
  }
  &:nth-child(4) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 0 18px 0;
  }
`;

const LogoCharity = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const CharityTitle = styled.h2`
  font-size: 18px;
  color: #707070;
`;
const CharityLocation = styled.h3`
  font-size: 14px;
  color: #707070;
`;
const ButtonGive = styled.div`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 130px;
  font-size: 14px;
  background: linear-gradient(
    -50deg,
    #f24405 20%,
    #f20c36 20%,
    #049dd9 30%,
    #f29f05 20%
  );
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0 0 0 / 30%);
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    background: linear-gradient(-60deg, #f20c36 20%, #f24405 20%);
    border-radius: 0px;
    transition: all 0.25s;
  }
`;

//Code SearchBar
const SearchBar = styled.div`
  text-align: center;
  display:flex;
  flex-direction:column;
  padding: 80px 0 0 0;
  .search {
    &__title {
      font-size: 18px;
      font-weight: 900;
      text-align: center;
      color: #ff8b88;
    }

    &__input {
      width: 50%;
      padding: 12px 24px;
      background-color: white;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 20px;

      color: #575756;

      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position: 95% center;
      border-radius: 50px;
      border: 1px solid white;
      transition: all 250ms ease-in-out;
      backface-visibility: hidden;
      transform-style: preserve-3d;

      &::placeholder {
        color: Black;
        text-transform: uppercase;
        letter-spacing: 1.5px;
      }

      &:hover,
      &:focus {
        padding: 12px 0;
        outline: 0;
        border: 1px solid transparent;
        border-bottom: 2px solid #f20c36;
        border-radius: 2px;
        background-position: 100% center;
      }
      @media (max-width: 768px) {
        width: 70%;
        line-height: 30px;
        &::placeholder {
          letter-spacing: 1px;
        }
      }
    }
  }
`;



const Icons = [
  {
    All: "BiInfinite",
    Healt: "GiHealthNormal",
    Religious: "FaPrayingHands",
    Art: "BiPaint",
    Veteran: "GiMeepleArmy",
    Conservation: "GiCastle",
    Nature: "MdEmojiNature",
    Other: "CgShapeSquare"
  }
];
